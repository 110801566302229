import { Outlet } from 'react-router-dom'
import { Container } from '@components/container'

import { Header } from './components'

export const AdminLayout = () => {
  return (
    <Container className="pt-5 max-w-[1400px]">
      <Header />
      <Outlet />
    </Container>
  )
}
