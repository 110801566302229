import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { toast } from '@components/toast'

import { getOrderUuid } from './api'

export const DeprecatedOrderPaymentLink = () => {
  const { orderId } = useParams<{ orderId: string }>()

  useQuery(getOrderUuid, {
    variables: { orderId: orderId as string },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      window.location.href = `${import.meta.env.VITE_REST_ENDPOINT}/order/${res.order_uuid}`
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  return <></>
}
