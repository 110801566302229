import { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from '@components/container'

import { Footer, Header } from './components'

type Props = {
  children?: ReactNode
}

export const NotFoundLayout:FC<Props> = (props) => {
  return (
    <div style={{ minHeight: '100vh' }} className="flex flex-col justify-between">
      <Container className="pt-5">
        <Header />
        {props.children ? props.children : <Outlet />}
      </Container>
      <div className="bg-neutral-800 mt-10">
        <Container>
          <Footer />
        </Container>
      </div>
    </div>
  )
}
