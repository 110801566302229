import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
  makeVar,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { toast } from '@components/toast'
import { ERROR } from '@constants/error'
import { tokenStore } from '@helpers/token-store'
import { createClient } from 'graphql-ws'

const wsLink = new GraphQLWsLink(createClient({ url: import.meta.env.VITE_WEBSOCKET_ENDPOINT }))

export const isAuthorizedState = makeVar(!!tokenStore.get())

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
})

export const forceLogout = () => {
  tokenStore.remove()
  toast.info('Log in again')
  window.location.href = '/create'
}

// Страницы, которые можно посетить без авторизации
const guestPages: string[] = ['/', '/create', '/oauth/facebook/callback']

const errorLink = onError(({
  graphQLErrors, forward, operation,
}) => {
  if (graphQLErrors?.length) {
    const isUnauthorized = graphQLErrors[0].extensions?.code === ERROR.UNAUTHORIZED
    const isGuestPage = !guestPages.includes(window.location.pathname)

    if (isUnauthorized && isGuestPage) {
      forceLogout()
    }
  }

  return forward(operation)
})

const authLink = new ApolloLink((operation, forward) => {
  const token = tokenStore.get()
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })
  return forward(operation)
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

export const apolloClient = new ApolloClient({
  link: errorLink.concat(concat(authLink, splitLink)),
  cache: new InMemoryCache(),
  connectToDevTools: import.meta.env.DEV,
})
