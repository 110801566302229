import { Outlet } from 'react-router-dom'
import { Container } from '@components/container'

import { Header } from './components'

export const PaymentLayout = () => {
  return (
    <div style={{ minHeight: '100vh' }} className="flex flex-col justify-between bg-primary">
      <Container className="pt-5">
        <Header />
        <Outlet />
      </Container>
    </div>
  )
}
