import { graphql } from '@api/gql/gql'

export const getOrderStripeSession = graphql(`
    query getOrderStripeSession($sessionId: String!) {
        order_stripe_session(session_id: $sessionId) {
            order_id
            email
            order_total
        }
    }
`)
