import {
  browserTracingIntegration,
  init as initSentry,
  replayIntegration,
} from '@sentry/react'

const init = () => {
  initSentry({
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [browserTracingIntegration(), replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: import.meta.env.VERSION,
  })
}

export const sentry = {
  init,
}
