import { Outlet } from 'react-router-dom'
import logo from '@assets/logo.png'
import { Container } from '@components/container'
import { Link } from '@nextui-org/link'

import { Footer } from './components'

export const OauthLayout = () => {
  return (
    <div style={{ minHeight: '100vh' }} className="flex flex-col justify-between">
      <Container className="pt-5">
        <div className="grid grid-cols-3 mb-8 px-4 lg:px-0">
          <div />

          <Link className="flex justify-center" href="/">
            <img src={logo} alt="Logo" className="object-contain max-w-40 lg:max-w-60" />
          </Link>
        </div>
        <Outlet />
      </Container>
      <div className="bg-neutral-800 mt-10">
        <Container>
          <Footer />
        </Container>
      </div>
    </div>
  )
}
