import { Outlet } from 'react-router-dom'
import { Container } from '@components/container'

export const PrintLayout = () => {
  return (
    <Container className="flex flex-col items-center">
      <Outlet />
    </Container>
  )
}
