import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Role } from '@api/gql/graphql'
import { RouteSpinner } from '@components/route-spinner'
import { AdminLayout } from '@layouts/admin-layout'
import { LandingLayout } from '@layouts/landing-layout'
import { MainLayout } from '@layouts/main-layout'
import { NotFoundLayout } from '@layouts/not-found-layout'
import { OauthLayout } from '@layouts/oauth-layout'
import { PaymentLayout } from '@layouts/payment-layout'
import { PrintLayout } from '@layouts/print-layout'
import { DeprecatedOrderPaymentLink } from '@pages/payment-page/deprecated-order-payment-link'
import { ProtectedRoute } from '@share/protected-route'

const AdminOrdersPage = lazy(
  () => import('@pages/admin-orders-page/admin-orders-page'),
)
const CreatePage = lazy(() => import('@pages/create-page/create-page'))
const OrdersPage = lazy(() => import('@pages/orders-page/orders-page'))
const PrintBookPage = lazy(() => import('@pages/print-page/print-book-page'))
const PrintSpinePage = lazy(() => import('@pages/print-page/print-spine-page'))
const ProfilePage = lazy(() => import('@pages/profile-page/profile-page'))
const AlbumPage = lazy(() => import('@pages/order-page/order-page'))
const FaqPage = lazy(() => import('@pages/static/faq-page'))
const TermsAndConditionsPage = lazy(() => import('@pages/static/terms-and-conditions'))
const ContactsPage = lazy(() => import('@pages/static/contacts-page'))
const NotFoundPage = lazy(() => import('@pages/static/not-found-page'))
const MainPage = lazy(() => import('@pages/static/main-page'))
const PaymentFailedPage = lazy(() => import('@pages/payment-page/payment-failed-page'))
const PaymentSuccessPage = lazy(() => import('@pages/payment-page/payment-success-page'))
const LoginFacebookPage = lazy(() => import('@pages/oauth/login-facebook-page'))
const DeprecatedCreatePage = lazy(() => import('@pages/create-page/deprecated-create-page'))

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<LandingLayout />}>
        <Route
          path="/"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <MainPage />
            </Suspense>
          )}
        />
      </Route>
      <Route element={<PaymentLayout />}>
        <Route
          path="/payment-failed"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <PaymentFailedPage />
            </Suspense>
          )}
        />
        <Route
          path="/payment-success"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <PaymentSuccessPage />
            </Suspense>
          )}
        />
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/create"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <CreatePage />
            </Suspense>
          )}
        />

        <Route
          path="/preview/highlights/:from_unix/:to_unix"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <DeprecatedCreatePage />
            </Suspense>
          )}
        />

        <Route
          path="/orders"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <OrdersPage />
            </Suspense>
          )}
        />
        <Route
          path="/orders/:orderId"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <AlbumPage />
            </Suspense>
          )}
        />
        <Route
          path="/profile"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <ProfilePage />
            </Suspense>
          )}
        />
        <Route
          path="/faq"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <FaqPage />
            </Suspense>
          )}
        />
        <Route
          path="/contacts"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <ContactsPage />
            </Suspense>
          )}
        />
        <Route
          path="/terms-and-conditions"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <TermsAndConditionsPage />
            </Suspense>
          )}
        />
      </Route>
      <Route
        path="/admin"
        element={(
          <ProtectedRoute roles={[Role.Admin, Role.Moderator]}>
            <AdminLayout />
          </ProtectedRoute>
        )}
      >
        <Route
          path="/admin"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <AdminOrdersPage />
            </Suspense>
          )}
        />
      </Route>
      <Route path="/print" element={<PrintLayout />}>
        <Route
          path="/print/book/:albumId"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <PrintBookPage />
            </Suspense>
          )}
        />
        <Route
          path="/print/spine/:albumId"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <PrintSpinePage />
            </Suspense>
          )}
        />
      </Route>
      <Route path="/oauth" element={<OauthLayout />}>
        <Route
          path="/oauth/facebook/callback"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <LoginFacebookPage />
            </Suspense>
          )}
        />
      </Route>

      {/* Поддержка старого способа открытия оплаты заказа */}
      <Route path="/api/orders/order-checkout/:orderId" element={<DeprecatedOrderPaymentLink />} />

      <Route element={<NotFoundLayout />}>
        <Route
          path="*"
          element={(
            <Suspense fallback={<RouteSpinner />}>
              <NotFoundPage />
            </Suspense>
          )}
        />
      </Route>
    </Routes>
  )
}
