import { useEffect } from 'react'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'

export const NotFoundPage = () => {
  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.NotFound,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.NotFound,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="text-xs lg:text-base flex flex-col justify-center items-center pt-40">
      <h1 className="text-xl lg:text-5xl font-bold mb-2">404</h1>
      <p>Page not found</p>
    </div>
  )
}

export default NotFoundPage
