import { graphql } from '@api/gql/gql'

export const getProfile = graphql(`
    query getProfile {
        profile {
            id
            name
            email
            role
            fb_id
            avatar_url
            permissions
        }
    }
`)
